var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      key: _vm.dialogKey,
      attrs: { "max-width": "456px", value: _vm.showQuestion },
      on: {
        "click:outside": function($event) {
          _vm.dialog == false
        },
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          _vm.dialog == false
        }
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c("v-card", { staticClass: "pa-5 pb-6", attrs: { rounded: "lg" } }, [
        _c("h2", { staticClass: "dialog-title" }, [
          _vm._v(" " + _vm._s(_vm.title) + " ")
        ]),
        _c("p", { staticClass: "mt-3" }, [_vm._v(_vm._s(_vm.description))]),
        _c(
          "div",
          { staticClass: "button-list-container" },
          _vm._l(_vm.buttonList, function(button, index) {
            return _c(
              "v-btn",
              {
                key: button.label,
                staticClass: "mx-2 dialog-button",
                attrs: {
                  color: index % 2 == 0 ? "inqliTableGray" : "inqliPurple",
                  outlined: index % 2 == 0,
                  dark: ""
                },
                on: {
                  click: function($event) {
                    return button.onClick(button.argument)
                  }
                }
              },
              [_vm._v(" " + _vm._s(button.label) + " ")]
            )
          }),
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }