var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.formattedContents,
          "expanded-menu-items": _vm.expandedMenuItems
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function(ref) {
              var props = ref.props
              return [
                _c("span", { staticClass: "content-title" }, [
                  _vm._v(" " + _vm._s(props.item.title) + " ")
                ])
              ]
            }
          },
          {
            key: "author",
            fn: function(ref) {
              var props = ref.props
              return [
                _c("avatar", {
                  attrs: { image: props.item.author.avatar, size: "small" }
                })
              ]
            }
          },
          {
            key: "type",
            fn: function(ref) {
              var props = ref.props
              return [_vm._v(" " + _vm._s(props.item.type) + " ")]
            }
          },
          {
            key: "updated_on",
            fn: function(ref) {
              var props = ref.props
              return [
                _vm._v(
                  " " + _vm._s(_vm.getFormatTime(props.item.updated_on)) + " "
                )
              ]
            }
          },
          {
            key: "created_on",
            fn: function(ref) {
              var props = ref.props
              return [
                _vm._v(
                  " " + _vm._s(_vm.getFormatTime(props.item.created_on)) + " "
                )
              ]
            }
          },
          {
            key: "expanded-row-content",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  { staticClass: "content-detail" },
                  [
                    _c("content-detail", {
                      attrs: { content: item, author: item.author }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("confirm-dialog", {
        attrs: {
          title: _vm.dialogTitle,
          buttonList: _vm.dialogButtonList,
          description: _vm.dialogDescription,
          dialogKey: _vm.dialogKey
        },
        model: {
          value: _vm.displayDialog,
          callback: function($$v) {
            _vm.displayDialog = $$v
          },
          expression: "displayDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }