import sendRequest from "../../../app/utils/send-request-helper";

export const getContentList = async (currentPage, limit) => {
  const data = await sendRequest({
    url: `${process.env.VUE_APP_URL}/resources/contents?page=${currentPage}&limit=${limit}&is_page=true`,
    method: "GET",
    isAuth: true,
  });
  return data;
};
