var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "app-header",
                [
                  _c("pagination", {
                    attrs: {
                      "current-page": _vm.currentPage,
                      "total-pages": _vm.totalPages
                    },
                    on: { update: _vm.updatePage }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "div",
            { staticClass: "button-list" },
            [
              _c(
                "inqli-button",
                {
                  attrs: { width: "160px", to: "/system-admin/contents/create" }
                },
                [_vm._v("Add content")]
              )
            ],
            1
          ),
          _c("content-table", {
            attrs: { contents: _vm.contents },
            on: { reload: _vm.loadContentList }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }