import { render, staticRenderFns } from "./ContentList.vue?vue&type=template&id=09bb0fec&scoped=true&"
import script from "./ContentList.vue?vue&type=script&lang=js&"
export * from "./ContentList.vue?vue&type=script&lang=js&"
import style0 from "./ContentList.vue?vue&type=style&index=0&id=09bb0fec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09bb0fec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/matt/Documents/inqli/product/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('09bb0fec')) {
      api.createRecord('09bb0fec', component.options)
    } else {
      api.reload('09bb0fec', component.options)
    }
    module.hot.accept("./ContentList.vue?vue&type=template&id=09bb0fec&scoped=true&", function () {
      api.rerender('09bb0fec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/system-admin/contents/content-list/ContentList.vue"
export default component.exports