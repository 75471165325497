<template>
  <!-- This component will open a dialog question to confirm the action -->
  <v-dialog
    max-width="456px"
    :value="showQuestion"
    @click:outside="dialog == false"
    @keydown.esc="dialog == false"
    :key="dialogKey"
    v-model="dialog"
  >
    <v-card class="pa-5 pb-6" rounded="lg">
      <h2 class="dialog-title">
        {{ title }}
      </h2>
      <p class="mt-3">{{ description }}</p>
      <div class="button-list-container">
        <v-btn
          v-for="(button, index) in buttonList"
          :key="button.label"
          :color="index % 2 == 0 ? 'inqliTableGray' : 'inqliPurple'"
          @click="button.onClick(button.argument)"
          :outlined="index % 2 == 0"
          class="mx-2 dialog-button"
          dark
        >
          {{ button.label }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: String,
    buttonList: Array,
    description: String,
    dialogKey: String,
  },

  computed: {
    showQuestion() {
      return this.confirmDialog;
    },
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.button-list-container {
  margin-top: 10px;
}
.dialog-title {
  color: $inqliPurple !important;
  font-size: 18px;
  font-weight: 800;
}
.dialog-button {
  font-weight: 600 !important;
}
</style>
