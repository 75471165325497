<template>
  <layout>
    <template #header>
      <app-header>
        <pagination
          :current-page="currentPage"
          :total-pages="totalPages"
          @update="updatePage"
        />
      </app-header>
    </template>
    <div class="content">
      <div class="button-list">
        <inqli-button width="160px" to="/system-admin/contents/create"
          >Add content</inqli-button
        >
      </div>
      <content-table
        :contents="contents"
        @reload="loadContentList"
      ></content-table>
    </div>
  </layout>
</template>
<script>
import Header from "../../../app/components/headers/DefaultHeader";
import DefaultLayout from "../../../app/components/layouts/DefaultLayout";
import Button from "../../../app/components/buttons/Button";
import ContentTable from "../components/content-table/ContentTable.vue";
import Pagination from "../../../app/components/Pagination.vue";
import { getContentList } from "./content-list";

export default {
  name: "ContentList",
  metaInfo: {
    title: "Content List",
  },
  components: {
    "app-header": Header,
    layout: DefaultLayout,
    "inqli-button": Button,
    "content-table": ContentTable,
    pagination: Pagination,
  },
  data() {
    return {
      contents: [],
      currentPage: 1,
      totalPages: 10,
      limit: 10,
    };
  },
  async mounted() {
    try {
      await this.loadContentList();
    } catch (error) {
      this.$store.dispatch(
        "callAlert",
        "An error occurred while getting content list"
      );
    }
  },
  methods: {
    async updatePage(page) {
      this.currentPage = page;
      await this.loadContentList();
    },
    async loadContentList() {
      const data = await getContentList(this.currentPage, this.limit);
      this.totalPages = data.total_pages;
      this.contents = data.list;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  padding: 15px 40px;
  width: 80%;
  margin: 0 auto;
}
.button-list {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
@media (max-width: $breakpoint-tablet-large) {
  .content {
    padding: 10px;
    width: 100%;
  }
}
</style>
