<template>
  <div>
    <data-table
      :headers="headers"
      :items="formattedContents"
      :expanded-menu-items="expandedMenuItems"
    >
      <template v-slot:title="{ props }">
        <span class="content-title">
          {{ props.item.title }}
        </span>
      </template>
      <template v-slot:author="{ props }">
        <avatar :image="props.item.author.avatar" size="small"></avatar>
      </template>
      <template v-slot:type="{ props }">
        {{ props.item.type }}
      </template>
      <template v-slot:updated_on="{ props }">
        {{ getFormatTime(props.item.updated_on) }}
      </template>
      <template v-slot:created_on="{ props }">
        {{ getFormatTime(props.item.created_on) }}
      </template>

      <template v-slot:expanded-row-content="{ item }">
        <div class="content-detail">
          <content-detail :content="item" :author="item.author" />
        </div>
      </template>
    </data-table>
    <confirm-dialog
      v-model="displayDialog"
      :title="dialogTitle"
      :buttonList="dialogButtonList"
      :description="dialogDescription"
      :dialogKey="dialogKey"
    />
  </div>
</template>
<script>
import Avatar from "../../../../app/components/avatars/Avatar.vue";
import ConfirmDialog from "../../../../app/components/dialogs/ConfirmDialog.vue";
import DataTable from "../../../../app/components/tables/DataTable.vue";
import ContentDetail from "../../../../contents/components/ContentDetail.vue";
import { getFriendlyFormatTime, deleteContent } from "./content-table";

export default {
  components: {
    avatar: Avatar,
    "confirm-dialog": ConfirmDialog,
    "data-table": DataTable,
    "content-detail": ContentDetail,
  },
  props: {
    contents: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      displayDialog: false,
      dialogTitle: "",
      dialogDescription: "",
      dialogButtonList: [
        {
          label: "Delete anyway",
          color: "inqliGreen",
          argument: "'",
          onClick: null,
        },
        {
          label: "Cancel",
          color: "inqliRed",
          argument: null,
          onClick: () => (this.displayDialog = false),
        },
      ],
      dialogKey: "",
    };
  },
  computed: {
    headers() {
      return [
        { text: "Title", align: "left", value: "title" },
        { text: "Author", align: "left", value: "author" },
        { text: "Type", align: "left", value: "type" },
        { text: "Last updated", align: "left", value: "updated_on" },
        { text: "Created", align: "left", value: "created_on" },
      ];
    },
    expandedMenuItems() {
      return [
        {
          label: "Edit content",
          ariaLabel: "edit-content",
          icon: "mdi-pencil",
          action: this.editContent,
        },
        {
          label: "View content",
          ariaLabel: "view-content",
          icon: "mdi-account-details",
          action: this.viewContent,
        },
        {
          label: "Delete content",
          ariaLabel: "delete-content",
          icon: "mdi-delete",
          action: this.openDeleteDialog,
        },
      ];
    },
    formattedContents() {
      return this.contents.map((content) => {
        return {
          ...content.content,
          author: { ...content.author },
          org: { ...content.org },
        };
      });
    },
  },
  methods: {
    editContent(content) {
      this.$router.push(`/system-admin/contents/edit/${content.id}`);
    },
    viewContent(content) {
      this.$router.push(`/contents/${content.id}`);
    },
    getFormatTime(createdOn) {
      return getFriendlyFormatTime(createdOn);
    },
    openDialog({
      title,
      description,
      buttonAcceptArgument,
      buttonAcceptOnClick,
      dialogKey,
    }) {
      this.dialogTitle = title;
      this.dialogDescription = description;
      this.dialogButtonList[0].argument = buttonAcceptArgument;
      this.dialogButtonList[0].onClick = buttonAcceptOnClick;
      this.dialogKey = dialogKey;
      this.displayDialog = true;
    },
    openDeleteDialog(content) {
      const contentId = content.id;
      this.openDialog({
        title: "Delete content",
        description: "This action can not be undone.",
        buttonAcceptArgument: contentId,
        buttonAcceptOnClick: this.deleteContentHandler,
        dialogKey: contentId,
      });
    },
    async deleteContentHandler(contentId) {
      try {
        this.displayDialog = false;
        await deleteContent(contentId);
        this.$emit("reload");
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while deleting content."
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$menu-content-border-radius: 10px;
.content-detail {
  max-width: 620px;
  margin: auto;
}
</style>
